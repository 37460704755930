import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import axios from 'axios'

const url = `https://www.instagram.com/graphql/query/?query_hash=003056d32c2554def87228bc3fd9668a&variables={"id":"563740867","first":9}`

const Section = styled.section`
  width: 100%;
  text-align: center;
  background-color: #171717;
`

const Content = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
`

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

const Gallery = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 1rem;
`

const GalleryLink = styled.div`
  width: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  filter: grayscale(1);
  transition: filter .2s ease;

  &:hover {
    filter: none;
  }

  @media (min-width: 480px) {
    width: calc(100%/3);
  }
`

const InstagramLink = styled.a`
  color: #FAF0E6;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const InstagramSection = () => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      contentfulContact {
        instagram
      }
    }
  `)

  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState(null)

  const fetchPosts = () => {
    setLoading(true)
    axios
      .get(url)
      .then(({ data }) => {
        setLoading(false)
        console.log(data)
        setPosts(data.data.user.edge_owner_to_timeline_media.edges)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <Section>
      <Content>
        <Title>Recent Work</Title>
        {loading ? (
          <p style={{ marginBottom: 24 }}>Loading posts from instagram...</p>
        ) : posts ? (
          <Gallery>
            {posts && posts.map(({ node }) => (
              <GalleryLink key={node.shortcode}>
                <a href={`https://www.instagram.com/p/${node.shortcode}`} target="_blank" rel="noopener noreferrer">
                  <img src={node.thumbnail_src} alt={node.accessibility_caption} width="100%" height="100%" style={{ objectFit: 'cover'}} />
                </a>
              </GalleryLink>
            ))}
          </Gallery>
        ) : (
          <p style={{ marginBottom: 24 }}>There was a problem loading posts from instagram.</p>
        )}
        <InstagramLink
          href={data.contentfulContact.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          {posts ? `See more on Instagram` : `View on Instagram`}
        </InstagramLink>
      </Content>
    </Section>
  )
}

export default InstagramSection
