import React from 'react'

const NoClassLogo = ({ width, height, colour }) => (
	<svg
		width={width}
		height={height}
		fill={colour}
		viewBox="0 0 400 244"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
	<g>
		<path d="M20,77.968V12.918l71.311,80.496c-0.32,0.044-0.641,0.077-0.979,0.093
			c4.168,2.202,10.125,0.826,10.125,0.826c0.198,8.256,2.385,15.687,6.155,22.155c3.969,6.88,9.726,12.246,17.069,16.236
			c7.542,3.991,15.679,5.917,24.411,5.917c9.129,0,17.464-2.063,24.609-6.467c4.564-2.615,7.938-6.056,11.313-9.907
			c0.594-0.413,0.992-0.963,1.389-1.514c7.541-9.494,5.755-22.842-4.167-30.135c-7.741-5.642-18.061-5.78-25.8-0.826
			c-4.168,1.927-7.938,5.367-7.145,11.009c0,0,0.396,4.128,6.351,3.99c-6.351-11.146,4.167-15.961,4.167-15.961
			c6.152-1.652,13.098-0.688,18.457,3.302c7.938,5.779,10.319,15.687,6.548,23.943c-2.183,3.44-4.762,6.33-7.541,8.669
			c-6.549,5.366-14.488,8.119-24.014,8.119c-11.113,0-20.044-4.267-26.792-12.66c-6.747-8.532-10.121-19.678-10.121-33.576
			c0-7.75,1.22-14.602,3.652-20.494c4.785,2.377,10.125,3.56,15.966,3.56c9.647,0,17.841-3.125,24.448-9.467
			c3.727-3.506,6.398-7.436,8.046-11.801c1.444,0.741,2.595,1.592,3.85,2.562c0,0,4.69,4.128,13.69,18.99V42.318
			c-5,3.027-7.737,3.027-7.737,3.027c-1.588,0-4.267-0.55-8.434-1.513c-0.048-0.01-0.042-0.019-0.09-0.03
			c0.444-2.18,0.694-4.455,0.694-6.831c0-9.007-3.292-16.636-9.899-22.886c-6.608-6.25-14.664-9.375-24.311-9.375
			c-9.779,0-17.969,3.125-24.577,9.283c-6.607,6.066-9.91,13.787-9.91,22.978c0,9.192,3.305,16.912,9.912,23.254
			c0.049,0.047,0.1,0.089,0.149,0.136c-5.52,6.99-9.004,15.223-10.143,24.477C98.985,88.822,96,92.683,91,93.401V18.145
			c0-5.501,1.198-9.26,2.982-11.323c0,0,1.99-1.822,9.921-5.822H74.758c7.535,4,9.091,5.959,9.091,5.959
			C85.435,9.16,86,13.057,86,18.695v54.185L25.784,1H1c8.724,4,10.905,7.197,10.905,7.197c1.189,1.238,2.181,3.117,2.577,5.317
			c0.199,2.75,0.656,17.031-9.653,19.093C9.388,34.259,15,31.76,15,31.76v43.183c0,5.776-0.656,10.147-2.241,12.485
			c0,0-1.449,2.572-9.38,6.572h29.344c-7.733-4-9.732-6.022-9.732-6.022C21.008,86.053,20,82.506,20,77.968z M143.555,39.913
			c-0.797,1.086-1.636,1.776-2.445,2.202c-1.017,0.169-2.022,0.361-3.009,0.586c-0.935-0.095-1.55-0.398-1.55-0.398
			c0.28,0.205,0.556,0.391,0.829,0.565c-8.61,2.104-16.059,6.398-22.348,12.796c-0.597-0.984-1.109-2.005-1.476-3.068
			c-2.643-7.261-0.265-14.154,1.189-17.279c1.454-2.574,3.569-4.963,6.079-6.893c5.154-3.677,10.969-4.78,16.123-3.401
			C140.383,26.402,148.578,30.814,143.555,39.913z M152.674,58.755c-4.494,5.699-10.44,8.548-17.709,8.548
			c-5.548,0-10.324-1.612-14.215-4.867c1.181-2.184,2.554-4.213,4.122-6.082c6.55-7.569,15.281-11.422,26.197-11.422
			c2.749,0,5.293,0.237,7.678,0.661C157.731,50.597,155.734,54.985,152.674,58.755z M117.388,15.281
			c4.494-5.422,10.573-8.18,18.105-8.18c7.136,0,12.951,2.758,17.312,8.364c4.493,5.515,6.741,12.868,6.741,21.967
			c0,1.602-0.105,3.135-0.264,4.634c-3.327-0.469-6.271-0.711-8.81-0.711c-0.307,0-0.606,0.015-0.911,0.02
			c3.739-5.618-4.789-19.462-18.694-18.649c-4.493-0.092-9.118,1.379-13.347,4.504c-2.907,2.022-5.154,4.688-6.872,7.537
			C111.045,26.77,113.291,20.336,117.388,15.281z"/>
		<path d="M285.283,114c-0.429,6,2.301,11.711,8.017,15.938c3.707,2.827,8.206,4.529,12.708,5.02
			c4.367,0.738,23.559,0.544,30.839-18.883c4.104-10.697,1.985-18.542-0.397-23.583c-2.117-4.427-5.426-8.963-10.854-13.513
			c-0.132,0-0.132-0.116-0.265-0.116c-2.118-1.845-4.367-3.317-7.015-4.669c-0.794-0.492-1.722-0.981-2.516-1.597
			c-5.955-3.32-9.793-6.024-11.647-8.115c-1.721-2.213-2.647-4.549-2.647-7.131c0-2.828,1.059-5.04,3.177-6.762
			c2.251-1.598,5.03-2.459,8.604-2.459c3.971,0,7.3,1.106,9.946,3.32c0,0,2.768,2.213,6.768,9.345V46.407
			c-1,1.722-3.723,1.722-3.723,1.722c-0.662,0-1.797-0.247-3.386-0.738c-3.441-1.107-6.655-1.598-9.567-1.598
			c-5.161,0-9.548,1.475-12.726,4.426c-3.31,2.951-5.038,6.763-5.038,11.436c0,0.737,0.127,1.352,0.127,2.09
			c-0.927,2.214-2.519,4.058-5.298,4.672c2.779,0.984,6.353-0.369,6.353-0.369c0,0.123,0,0.246,0.132,0.369
			c0.795,1.968,1.984,3.935,3.706,5.657c1.721,1.721,5.162,4.057,10.191,7.009c1.854,1.106,3.706,1.967,5.029,3.074
			c6.088,4.427,16.148,15.001,12.575,31.601c-0.529,1.599-1.191,3.074-1.985,4.427c-1.588,2.828-3.574,5.286-5.824,7.378
			c-7.28,5.409-18.928,5.286-26.869-0.738c-3.177-2.336-5.294-5.165-6.486-8.238c0.133-0.123,0.133-0.123,0.133-0.246
			c0,0,1.986,1.107,4.368,0.984c-1.853-0.615-2.78-2.174-3.044-3.772c-0.101-0.371-0.119-0.551-0.126-1.551h3.568
			c-1.481,0-2.631-1.166-3.523-1.664c0.02-0.194,0.034-0.281,0.081-0.494c1.06-5.779,6.883-10.769,13.633-11.507
			c4.367-0.492,8.339,0.764,10.721,3.223c1.588,1.967,4.765,7.268-1.456,10.834c-3.44,1.476-5.029-0.854-5.029-0.854
			c2.646,5.902,7.81,3.814,7.81,3.814c4.5-1.722,4.633-12.909-3.971-16.967c-2.648-1.598-5.956-2.335-9.53-1.844
			c-7.432,0.805-13.587,6.027-14.997,12.336c-0.001,0-0.004-0.002-0.004-0.002c-1.312-1.011-2.623-3.234-3.79-6.669l-5.538-15.763
			c0,0,2.624-2.424,3.207-5.253c-1.312,1.111-2.77,1.919-4.228,2.526l-11.659-32.943c0,0-1.895-5.772,1.457-6.772h-15.012
			c3.208,1,1.312,6.772,1.312,6.772l-2.479,7.175c-2.04,3.839-5.537,8.74-10.639,9.751c3.352,1.111,7.434-0.53,7.434-0.53
			l-8.892,25.249c0,0-8.453-2.027-9.473-8.999c-0.146,7.376,9.182,9.797,9.182,9.797l-2.332,6.26c0,0-3.061,8.525-10.493,12.525
			h18.364c-5.685-3-5.685-6.262-5.685-6.262c0-1.516,0.583-3.939,1.895-7.374l1.604-4.597c6.559,0.505,10.785-3.309,13.263-6.846
			c6.121,2.627,10.931,3.348,14.574,3.146l4.81,13.647c0.292,0.909,0.584,1.821,0.584,2.631c0,0-0.002,0.034-0.007,0.082
			c-2.882-0.318-6.361,0.412-9.452,4.157c0,0,2.477-2.212,6.535-0.463c-0.819,0.651-1.854,1.878-3.199,1.878h6.4
			c2.586,3,3.914,5.208,4.366,7.408c-0.159,3.632-1.584,12.104-12.993,10.893c-7.767-0.883-18.058-4.244-38.342-9.418
			c-4.913-1.21-9.619-1.93-13.619-2.26V58.417c0-4.183,0.783-6.929,2.051-8.8c0,0,1.261-1.617,6.647-4.617h-27.731
			c5.705,3,7.052,4.287,7.052,4.287c1.268,1.651,1.98,4.176,1.98,8.14v5.174c-1,3.303-3.011,6.605-7.131,7.377
			c3.011,1.101,7.131-0.22,7.131-0.22v50.865c0,0-14.184,1.321-20.205,7.485c8.398-4.624,22.463-2.531,51.938,6.716
			c16.957,5.284,28.345,3.317,35.001,0.786c11.269-3.24,15.104-14.61,12.415-21.61H285.283z M256.257,83.088
			c-1.748-0.809-1.602-4.401-1.602-4.805c0,1.111-2.77,17.717-14.139,16.717h-0.146l2.915-8.128l1.312-4.266l10.057-28.81
			l10.056,28.792l1.312,3.836C266.022,86.425,260.631,85.513,256.257,83.088z"/>
		<path d="M395.75,98.666c-2.532-5.313-6.49-10.775-12.981-16.237c-0.159,0-0.159-0.147-0.316-0.147
			c-2.533-2.214-5.224-3.986-8.39-5.609c-0.95-0.591-2.059-1.181-3.009-1.919c-7.124-3.986-11.715-7.232-13.932-9.742
			c-2.059-2.657-3.166-5.462-3.166-8.562c0-3.395,1.266-6.052,3.799-8.119c2.691-1.919,6.016-2.952,10.29-2.952
			c4.75,0,8.711,1.329,11.877,3.985c0,0,3.078,2.658,8.078,11.219v-17.27c-2,2.066-4.438,2.066-4.438,2.066
			c-0.791,0-2.137-0.295-4.038-0.886c-4.115-1.329-7.955-1.919-11.439-1.919c-6.174,0-11.418,1.771-15.218,5.314
			c-3.957,3.542-6.025,8.119-6.025,13.728c0,0.886,0.154,1.624,0.154,2.509c-1.11,2.657-3.011,4.871-6.336,5.609
			c3.325,1.181,7.597-0.442,7.597-0.442c0,0.147,0,0.295,0.16,0.442c0.949,2.362,2.373,4.724,4.43,6.791
			c2.06,2.067,6.175,4.871,12.191,8.414c2.216,1.328,4.433,2.361,6.016,3.69c7.282,5.314,19.313,18.009,15.04,37.936
			c-0.634,1.919-1.425,3.689-2.375,5.313c-1.9,3.395-4.275,6.348-6.965,8.857c-8.709,6.494-22.64,6.347-32.138-0.887
			c-3.8-2.805-6.333-6.199-7.758-9.89c0.158-0.147,0.158-0.147,0.158-0.296c0,0,2.375,1.329,5.225,1.183
			c-2.217-0.738-3.546-2.362-3.863-4.281c-0.157-0.59-0.378-1.329-0.378-2.215c0,0,0,0,0-0.147v-0.147
			c0-0.738,0.221-1.328,0.378-2.066c1.269-6.938,8.344-12.99,16.418-13.875c5.225-0.591,10.028,0.885,12.878,3.838
			c1.9,2.361,5.728,8.709-1.714,12.989c-4.116,1.771-6.002-1.033-6.002-1.033c3.166,7.085,9.348,4.576,9.348,4.576
			c5.382-2.067,5.543-15.5-4.746-20.37c-3.167-1.919-7.123-2.805-11.397-2.215c-9.498,1.034-17.255,8.119-18.205,16.385
			c-1.74,7.379,1.426,15.793,9.185,21.551c4.431,3.395,9.814,5.314,15.197,5.904c5.225,0.886,28.18,0.59,36.887-22.733
			C401.133,114.165,398.6,104.718,395.75,98.666z"/>
		<path d="M84.915,182.262c0,0-0.634,0.738-3.881,2.738h15.509c-3.155-2-3.669-2.56-3.669-2.56
			c-0.631-0.837-0.874-2.62-0.874-5.005V147h6.438c2.884,0,5.145,0.196,6.587,0.971c0,0,0.975,0.615,3.975,8.899v-13.769
			c-5,1.49-8.759,1.898-8.759,1.898H77.247c0,0-3.247-0.408-8.247-1.898v13.769c3-8.105,3.853-8.779,3.853-8.779
			c1.353-0.895,3.673-1.091,6.739-1.091H86v7.009c0,1.908-1.623,3.875-4.058,4.353c1.713,0.596,4.058-0.12,4.058-0.12v18.956
			C86,179.521,85.637,181.246,84.915,182.262z"/>
		<path d="M174.224,185c-3.155-2-4.009-2.56-4.009-2.56c-0.631-0.837-1.215-2.62-1.215-5.005V147h7.118
			c2.885,0,4.806,0.196,6.248,0.971c0,0,1.634,0.615,3.634,8.899v-13.769c-5,1.49-8.078,1.898-8.078,1.898h-22.994
			c0,0-2.928-0.408-7.928-1.898v13.769c2-8.105,3.693-8.779,3.693-8.779c1.353-0.895,3.514-1.091,6.579-1.091H164v7.009
			c-1,1.908-1.623,3.875-4.058,4.353c1.713,0.596,4.058-0.12,4.058-0.12v18.956c0,2.324-0.523,4.049-1.245,5.064
			c0,0-0.794,0.738-4.041,2.738h2.577c-4.666,1-7.708,1-7.708,1h-22.993c0,0-3.592,0.066-7.592-1.425v13.77
			c2-8.106,3.525-9.017,3.525-9.017c1.353-0.895,3.345-1.328,6.41-1.328H139v7.483c0,1.907-1.624,3.875-4.058,4.352
			c1.713,0.596,4.058-0.118,4.058-0.118v18.953c0,2.325-0.192,3.814-0.913,4.828c0,0-0.462,1.502-3.709,2.502h15.51
			c-3.156-1-3.841-2.324-3.841-2.324c-0.632-0.834-1.047-2.382-1.047-4.767V188h6.781c2.886,0,4.974,0.434,6.417,1.209
			c0,0,1.802,0.851,3.802,9.136V185H174.224z"/>
		<path d="M219.471,147c2.886,0,5.13,0.196,6.572,0.971c0,0,0.957,0.615,3.957,8.899v-13.769
			c-5,1.49-8.726,1.898-8.726,1.898H198.28c0,0-3.28-0.408-8.28-1.898v13.769c3-8.105,3.87-8.779,3.87-8.779
			c1.353-0.895,3.69-1.091,6.755-1.091H207v7.009c0,1.908-1.623,3.875-4.058,4.353c1.712,0.596,4.058-0.12,4.058-0.12v18.956
			c0,2.324-0.348,4.049-1.067,5.064c0,0-0.618,0.738-3.865,2.738h15.51c-3.155-2-3.687-2.56-3.687-2.56
			c-0.631-0.837-0.891-2.62-0.891-5.005V147H219.471z"/>
		<path d="M118.184,181.061c0-0.891,0.361-2.445,1.17-4.464l0.99-2.769c4.05,0.298,6.659-1.976,8.189-4.055
			c3.779,1.545,6.75,1.951,8.999,1.831l2.97,8.015c0.181,0.535,0.36,1.206,0.36,1.682c0,0,0,1.699-3.78,3.699h15.389
			c-3.06-2-3.869-2.217-3.869-2.217c-0.81-0.594-1.62-1.971-2.34-3.988l-3.42-9.302c0,0,1.62-1.442,1.98-3.106
			c-0.81,0.655-1.71,1.121-2.609,1.478l-7.2-19.624c0,0-1.17-3.24,0.9-4.24h-9.27c1.98,1,0.81,4.236,0.81,4.236l-1.53,4.346
			c-1.259,2.256-3.418,5.2-6.568,5.795c2.07,0.653,4.589-0.28,4.589-0.28l-5.49,14.858c0,0-5.219-1.184-5.849-5.282
			c-0.09,4.336,5.669,5.763,5.669,5.763l-1.44,3.941c0,0-1.89,4.623-6.479,7.623h11.339C118.184,183,118.184,181.061,118.184,181.061
			z M122.324,168.379l0.809-2.43l6.209-16.896l6.209,16.943l0.81,2.266c0,0-3.33-0.53-6.03-1.956c-1.08-0.475-0.99-2.74-0.99-2.979
			c0,0.653-1.709,10.672-8.729,9.672h-0.09L122.324,168.379z"/>
		<path d="M274.215,149.623c-4.496-4.073-9.98-6.11-16.542-6.11c-6.653,0-12.227,2.037-16.723,6.051
			c-4.495,3.953-6.742,8.985-6.742,14.975c0,5.99,2.247,11.021,6.742,15.154c4.496,4.134,10.069,6.171,16.542,6.171
			c6.563,0,12.137-2.037,16.632-6.171c4.586-4.133,6.834-9.164,6.834-15.154C280.958,158.667,278.71,153.696,274.215,149.623z
			 M269.54,178.734c-3.058,3.714-7.103,5.571-12.048,5.571c-4.943,0-8.989-1.797-11.957-5.452c-1.078-1.257-2.066-2.635-2.606-4.132
			c-1.798-4.732-0.18-9.226,0.81-11.262c0.989-1.678,2.427-3.234,4.136-4.492c3.507-2.396,7.461-3.115,10.969-2.217
			c2.337,0.899,7.911,3.774,4.494,9.704c-2.157,2.815-4.766,1.557-4.766,1.557c5.395,3.774,8.722-0.418,8.722-0.418
			c2.876-3.534-2.967-12.879-12.586-12.34c-3.058-0.06-6.204,0.898-9.08,2.936c-1.979,1.316-3.508,3.055-4.677,4.911
			c0.27-5.212,1.799-9.404,4.585-12.698c3.058-3.534,7.193-5.332,12.318-5.332c4.854,0,8.81,1.798,11.775,5.451
			c3.058,3.595,4.586,8.386,4.586,14.316C274.215,170.408,272.687,175.021,269.54,178.734z"/>
		<path d="M330.651,164.538c0-5.871-2.247-10.842-6.743-14.915c-4.495-4.073-9.979-6.11-16.541-6.11
			c-6.652,0-12.227,2.037-16.722,6.051c-4.495,3.953-6.743,8.985-6.743,14.975c0,5.99,2.248,11.021,6.743,15.154
			c3.27,3.007,7.116,4.89,11.457,5.701c-4.611,0.751-8.611,2.642-11.999,5.652c-4.495,3.937-6.742,8.945-6.742,14.908
			s2.247,10.973,6.742,15.087c4.497,4.116,10.07,6.144,16.542,6.144c6.562,0,12.138-2.027,16.632-6.144
			c4.586-4.114,6.834-9.124,6.834-15.087c0-5.845-2.248-10.794-6.743-14.849c-3.277-2.957-7.088-4.823-11.451-5.624
			c4.55-0.767,8.529-2.688,11.902-5.789C328.404,175.56,330.651,170.528,330.651,164.538z M318.783,192
			c3.056,3.579,4.585,8.349,4.585,14.253c0,5.546-1.529,10.139-4.676,13.834c-3.056,3.698-7.102,5.547-12.047,5.547
			c-4.944,0-8.99-1.789-11.957-5.427c-1.078-1.252-2.066-2.624-2.606-4.114c-1.797-4.712-0.181-9.185,0.81-11.213
			c0.989-1.668,2.427-3.219,4.136-4.471c3.506-2.387,7.461-3.102,10.968-2.209c2.337,0.896,7.912,3.759,4.494,9.662
			c-2.157,2.804-4.765,1.551-4.765,1.551c5.395,3.757,8.721-0.418,8.721-0.418c2.877-3.519-2.967-12.82-12.585-12.284
			c-3.059-0.06-6.205,0.895-9.08,2.923c-1.979,1.312-3.507,3.041-4.677,4.889c0.271-5.188,1.798-9.362,4.585-12.643
			c3.058-3.518,7.193-5.308,12.318-5.308C311.859,186.572,315.815,188.362,318.783,192z M307.188,184.306
			c-4.946,0-8.989-1.797-11.957-5.452c-1.079-1.257-2.068-2.635-2.605-4.132c-1.8-4.732-0.181-9.226,0.809-11.262
			c0.988-1.678,2.427-3.234,4.135-4.492c3.505-2.396,7.461-3.115,10.968-2.217c2.337,0.899,7.911,3.774,4.495,9.704
			c-2.158,2.815-4.765,1.557-4.765,1.557c5.394,3.774,8.721-0.418,8.721-0.418c2.877-3.534-2.968-12.879-12.587-12.34
			c-3.056-0.06-6.202,0.898-9.08,2.936c-1.978,1.316-3.506,3.055-4.675,4.911c0.27-5.212,1.799-9.404,4.585-12.698
			c3.057-3.534,7.192-5.332,12.316-5.332c4.855,0,8.81,1.798,11.777,5.451c3.057,3.595,4.584,8.386,4.584,14.316
			c0,5.57-1.527,10.183-4.674,13.896C316.178,182.448,312.131,184.306,307.188,184.306z"/>
		<path d="M111,206.679c0-0.06,0-0.06,0-0.06s0.014-0.06-0.077-0.06c-1.445-1.19-3.063-2.142-4.778-3.032
			c-0.632-0.298-1.17-0.654-1.803-1.012c-3.973-2.14-6.59-3.925-7.854-5.292c-1.174-1.428-1.805-2.914-1.805-4.64
			c0-1.783,0.723-3.269,2.168-4.34c1.535-1.071,3.431-1.604,5.869-1.604c2.618,0,4.962,0.711,6.769,2.14c0,0,1.511,1.487,4.511,6.065
			v-9.335c-1,1.13-2.435,1.13-2.435,1.13c-0.451,0-1.217-0.179-2.301-0.477c-2.348-0.715-4.536-1.071-6.522-1.071
			c-3.521,0-6.512,0.951-8.68,2.855c-2.257,1.902-3.436,4.4-3.436,7.433c0,0.476,0.088,0.951,0.088,1.368
			c-0.632,1.427-1.717,2.676-3.613,3.032c1.896,0.596,4.333-0.237,4.333-0.237c0,0.06,0,0.179,0.09,0.237
			c0.542,1.309,1.354,2.557,2.528,3.628c1.175,1.13,3.521,2.676,6.953,4.578c1.264,0.715,2.438,1.368,3.431,1.964
			c4.063,2.973,11.016,9.812,8.578,20.634c-0.361,0.951-0.812,1.903-1.354,2.854c-1.083,1.785-2.438,3.39-3.973,4.758
			c-4.966,3.509-12.912,3.39-18.329-0.476c-2.167-1.487-3.612-3.39-4.425-5.353c0.09-0.06,0.09-0.118,0.09-0.118
			s1.354,0.653,2.979,0.535c-1.263-0.357-1.813-1.25-1.994-2.26c-0.09-0.356-0.008-0.774-0.008-1.19v-0.059c0-0.06,0-0.06,0-0.119
			c0-0.357-0.082-0.773,0.008-1.069c0.722-3.808,4.654-7.077,9.26-7.613c2.979-0.298,5.577,0.595,7.292,2.141
			c1.084,1.25,3.241,4.698-1.003,7.018c-2.347,0.951-3.436-0.594-3.436-0.594c1.806,3.864,5.324,2.496,5.324,2.496
			c3.07-1.129,3.16-8.385-2.709-11.001c-1.806-1.011-4.064-1.487-6.502-1.248c-5.417,0.595-9.571,4.519-10.384,8.86
			c-0.723,4.104,0.812,8.623,5.236,11.714c2.529,1.845,5.598,2.916,8.668,3.212c2.89,0.417,16.072,0.297,21.039-12.311
			c2.799-6.957,1.34-12.071-0.285-15.342C117.063,212.566,115,209.652,111,206.679z"/>
		<path d="M207.121,188.586c0,0,0.852-0.586,3.992-2.586h-15.439c2.962,2,3.42,2.705,3.42,2.705
			c0.717,0.896,0.905,2.585,0.905,4.912v24.164c-3,4.477-7.19,6.684-12.396,6.684c-3.501,0-5.93-0.955-7.905-2.806
			c-1.975-1.909-2.699-4.474-2.699-7.756v-20.345c0-2.387,0.181-4.078,0.898-4.973c0,0,0.54-0.586,3.771-2.586h-15.799
			c3.231,2,4.045,2.586,4.045,2.586c0.629,0.954,1.084,2.646,1.084,5.031v1.97c-1,1.85-1.616,3.759-4.04,4.236
			c1.795,0.597,4.04-0.12,4.04-0.12v13.664c0,4.176,1.25,7.517,3.943,10.023c2.604,2.565,6.065,3.817,10.643,3.817
			c5.656,0,10.414-2.207,14.414-6.622V226h11.113c-3.23-1-3.992-2.498-3.992-2.498c-0.719-0.954-1.121-2.438-1.121-4.825v-25.06
			C206,191.231,206.402,189.54,207.121,188.586z"/>
		<path d="M254.311,191.864c-4.498-3.875-10.522-5.864-17.988-5.864h-20.236c3.148,2,3.852,2.584,3.852,2.584
			c0.72,0.954,1.062,2.645,1.062,5.029v1.015c0,2.146-1.439,4.651-4.228,5.188c1.8,0.655,4.228-0.179,4.228-0.179v2.504
			c-6,2.804-8.978,9.183-6.908,15.206c0.989,2.803,2.977,5.012,5.494,6.561c0,0-1.16,1.092-3.5,2.092h10.523c0.54,0,1.079,0,1.529,0
			h8.724c7.645,0,13.58-1.672,17.809-5.191c4.227-3.518,6.386-8.181,6.386-14.085C261.057,200.702,258.808,195.8,254.311,191.864z
			 M221,218.659c0,1.969-0.253,3.4-0.703,4.413c-1.619-1.371-2.885-3.16-3.605-5.188c-1.709-4.83,0.309-9.958,4.309-12.938V218.659z
			 M249.633,220.07c-3.058,3.16-7.284,4.93-12.681,4.93h-4.497c-2.249,0-3.672-0.695-4.391-1.77c-0.81-1.012-1.064-3.14-1.064-6.002
			v-14.67c3-0.596,7,0.238,9.788,2.028c0,0,5.074,4.771-1.134,9.779c3.509,1.252,4.652-1.014,4.652-1.014
			c1.618-3.041,0.271-5.605-1.617-7.515c-2.787-3.339-7.689-5.307-11.689-5.128V188h8.153c6.027,0,10.703,1.525,14.031,5.042
			c3.417,3.52,5.126,8.147,5.126,13.993C254.311,212.521,252.781,216.852,249.633,220.07z"/>
		<path d="M275.087,188.571c0,0,0.728-0.571,3.856-2.571h-15.643c3.217,2,3.825,2.512,3.825,2.512
			c0.624,0.896,0.874,2.63,0.874,5.08v0.956c0,2.151-1.34,4.72-4.202,5.258c1.878,0.657,4.202-0.179,4.202-0.179v19.059
			c0,2.45-0.25,3.986-0.874,4.881c0,0-0.608,1.434-3.825,2.434h15.643c-3.129-1-3.856-2.434-3.856-2.434
			c-0.717-0.955-1.087-2.491-1.087-4.881v-25.094C274,191.201,274.37,189.527,275.087,188.571z"/>
	</g>
	</svg>
)

export default NoClassLogo
