import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import Icon from './icon'
import { gun } from './constants'

const options = {
  renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])
}

const AboutSection = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
`

const Content = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
`

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

const IconWrapper = styled.div`
  margin-top: 2rem;
`

const About = () => {
  const data = useStaticQuery(graphql`
    query SectionContentQuery {
      contentfulAbout(title: {eq: "What we do"}) {
        title
        content {
          json
        }
      }
    }
  `)

  return (
    <AboutSection>
      <Content>
        <Title>{data.contentfulAbout.title}</Title>
        {documentToReactComponents(data.contentfulAbout.content.json, options)}
        <IconWrapper>
          <Icon icon={gun} colour="#FAF0E6" size="48" />
        </IconWrapper>
      </Content>
    </AboutSection>
  )
}

export default About
