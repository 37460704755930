import React from 'react'

const TatsByText = ({ width, height, colour }) => (
	<svg
		width={width}
		height={height}
		fill={colour}
		viewBox="0 0 629 200"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
	<g>
		<path d="M152.492,1v17.586c-2.669-10.582-4.152-11.647-4.152-11.647c-1.582-0.99-3.855-1.522-7.019-1.522h-7.316
			v39.433c0,3.045,0.396,5.177,1.087,6.243c0,0,0.692,1.066,4.152,3.121H122.24c3.56-2.132,4.251-3.35,4.251-3.35
			c0.791-1.294,1.187-3.349,1.187-6.318V20.336c0,0-2.57,0.914-4.449,0.152c2.67-0.609,3.955-3.122,4.449-5.557V5.416h-7.02
			c-3.361,0-5.733,0.533-7.218,1.674c0,0-1.581,1.142-4.053,11.496V1c5.142,1.904,8.7,1.904,8.7,1.904h25.211
			C143.299,2.904,146.956,2.904,152.492,1z"/>
		<path d="M243.296,1v17.586c-2.67-10.582-4.152-11.647-4.152-11.647c-1.582-0.99-3.855-1.522-7.02-1.522h-7.316
			v39.433c0,3.045,0.396,5.177,1.088,6.243c0,0,0.692,1.066,4.152,3.121h-17.004c3.559-2.132,4.251-3.35,4.251-3.35
			c0.79-1.294,1.186-3.349,1.186-6.318V20.336c0,0-2.57,0.914-4.448,0.152c2.669-0.609,3.955-3.122,4.448-5.557V5.416h-7.019
			c-3.361,0-5.734,0.533-7.218,1.674c0,0-1.581,1.142-4.053,11.496V1c5.141,1.904,8.7,1.904,8.7,1.904h25.21
			C234.102,2.904,237.76,2.904,243.296,1z"/>
		<path d="M293.588,1v17.586c-2.67-10.582-4.152-11.647-4.152-11.647c-1.583-0.99-3.856-1.522-7.02-1.522H275.1
			v39.433c0,3.045,0.396,5.177,1.088,6.243c0,0,0.692,1.066,4.152,3.121h-17.005c3.56-2.132,4.252-3.35,4.252-3.35
			c0.79-1.294,1.186-3.349,1.186-6.318V20.336c0,0-2.57,0.914-4.448,0.152c2.669-0.609,3.954-3.122,4.448-5.557V5.416h-7.019
			c-3.361,0-5.734,0.533-7.218,1.674c0,0-1.581,1.142-4.053,11.496V1c5.141,1.904,8.699,1.904,8.699,1.904h25.211
			C284.394,2.904,288.052,2.904,293.588,1z"/>
		<path d="M352.834,28.462c0,7.631-2.485,14.041-7.559,19.307c-4.973,5.266-11.142,7.86-18.402,7.86
			c-7.161,0-13.329-2.594-18.303-7.86c-4.973-5.266-7.461-11.676-7.461-19.307c0-7.632,2.488-14.042,7.461-19.079
			c4.974-5.113,11.142-7.708,18.502-7.708c7.261,0,13.33,2.594,18.303,7.784C350.349,14.649,352.834,20.983,352.834,28.462z
			 M345.375,28.843c0-7.555-1.691-13.66-5.072-18.239c-3.284-4.655-7.661-6.944-13.033-6.944c-5.669,0-10.244,2.289-13.626,6.792
			c-3.084,4.197-4.775,9.54-5.073,16.179c1.293-2.366,2.983-4.579,5.172-6.258c3.185-2.594,6.665-3.816,10.048-3.739
			c10.643-0.688,17.108,11.217,13.926,15.72c0,0-3.682,5.342-9.65,0.534c0,0,2.886,1.603,5.273-1.984
			c3.779-7.555-2.388-11.218-4.973-12.363c-3.88-1.145-8.257-0.229-12.138,2.823c-1.889,1.603-3.48,3.587-4.574,5.724
			c-1.095,2.595-2.886,8.318-0.896,14.347c0.597,1.907,1.691,3.663,2.886,5.266c3.283,4.654,7.759,6.944,13.229,6.944
			s9.945-2.366,13.328-7.097C343.684,41.817,345.375,35.94,345.375,28.843z"/>
		<path d="M410.33,28.462c0,7.631-2.485,14.041-7.56,19.307c-4.973,5.266-11.142,7.86-18.402,7.86
			c-7.161,0-13.329-2.594-18.303-7.86c-4.973-5.266-7.461-11.676-7.461-19.307c0-7.632,2.488-14.042,7.461-19.079
			c4.974-5.113,11.142-7.708,18.502-7.708c7.261,0,13.33,2.594,18.303,7.784C407.845,14.649,410.33,20.983,410.33,28.462z
			 M402.87,28.843c0-7.555-1.691-13.66-5.072-18.239c-3.284-4.655-7.66-6.944-13.032-6.944c-5.67,0-10.245,2.289-13.627,6.792
			c-3.084,4.197-4.775,9.54-5.073,16.179c1.293-2.366,2.983-4.579,5.172-6.258c3.185-2.594,6.665-3.816,10.048-3.739
			c10.644-0.688,17.108,11.217,13.926,15.72c0,0-3.682,5.342-9.65,0.534c0,0,2.886,1.603,5.273-1.984
			c3.78-7.555-2.388-11.218-4.973-12.363c-3.88-1.145-8.257-0.229-12.138,2.823c-1.889,1.603-3.48,3.587-4.574,5.724
			c-1.095,2.595-2.886,8.318-0.896,14.347c0.596,1.907,1.691,3.663,2.885,5.266c3.283,4.654,7.759,6.944,13.229,6.944
			c5.472,0,9.946-2.366,13.329-7.097C401.179,41.817,402.87,35.94,402.87,28.843z"/>
		<path d="M448.782,60.19c-5.488,16.15-20.053,16.302-23.246,15.769c-3.392-0.38-6.784-1.751-9.579-4.113
			c-4.891-3.962-6.584-9.75-5.787-15.007c0.896-5.562,5.489-10.59,11.475-11.351c2.694-0.305,5.188,0.303,7.184,1.6
			c6.484,3.352,6.386,12.646,2.993,14.093c0,0-3.891,1.753-5.888-3.2c0,0,1.198,1.981,3.793,0.762c4.689-2.971,2.295-7.39,1.098-8.99
			c-1.896-1.981-4.788-3.123-8.083-2.742c-5.088,0.686-9.479,4.875-10.275,9.751c-0.099,0.38-0.099,0.915-0.099,1.371
			c0,0.076,0,0.076,0,0.152v0.076c0,0.534,0,1.067,0.099,1.524c0.198,1.295,0.898,2.438,2.294,2.896
			c-1.794,0.152-3.293-0.686-3.293-0.686s0,0.076-0.1,0.152c0.898,2.513,2.493,4.951,4.89,6.855
			c5.985,4.952,14.766,5.104,20.254,0.61c1.696-1.752,3.193-3.81,4.39-6.095c0.599-1.219,1.097-2.438,1.496-3.657
			c2.694-13.865-4.985-22.626-9.479-26.434c-1.097-0.762-2.393-1.601-3.791-2.515c-3.791-2.437-6.386-4.417-7.682-5.865
			c-1.299-1.372-2.197-2.971-2.793-4.647c-0.102-0.076-0.102-0.229-0.102-0.304c0,0-2.693,1.066-4.789,0.304
			c2.096-0.457,3.293-2.057,3.991-3.885c0-0.533-0.1-1.142-0.1-1.752c0-3.885,1.298-7.085,3.792-9.523
			c2.394-2.437,5.687-3.655,9.577-3.655c2.196,0,4.591,0.457,7.185,1.371c1.197,0.38,1.996,0.609,2.494,0.609
			c0,0,1.896,0,2.593-1.447v11.959c-2.892-5.865-4.887-7.77-4.887-7.77c-1.996-1.829-4.491-2.742-7.385-2.742
			c-2.692,0-4.789,0.686-6.484,2.057c-1.598,1.372-2.394,3.276-2.394,5.561c0,2.21,0.696,4.114,1.993,5.942
			c1.398,1.752,4.291,4.038,8.681,6.78c0.699,0.458,1.298,0.915,1.997,1.295c1.896,1.143,3.692,2.361,5.288,3.886
			c0.1,0,0.1,0.075,0.1,0.075s0,0,0,0.077c4.189,3.809,6.686,7.542,8.28,11.198C450.28,44.725,451.877,51.276,448.782,60.19z"/>
		<path d="M523.069,41.77c0,3.754-1.396,6.818-4.288,9.117c-2.894,2.298-6.683,3.524-11.371,3.524h-22.439
			c3.592-2.145,4.39-3.295,4.39-3.295c0.798-1.226,1.196-3.371,1.196-6.436V27.825c-2.492-1.226-4.688-3.371-5.784-6.283
			c-2.295-5.67-0.102-12.106,4.886-15.094c-0.1-0.153-0.198-0.306-0.298-0.382c0,0-0.699-1.226-4.39-3.295h18.353
			c5.285,0,9.474,1.149,12.465,3.448c2.993,2.298,4.487,5.363,4.487,9.118c0,5.516-3.288,9.424-9.772,11.723v0.229
			c3.689,0.767,6.683,2.529,9.074,5.21C521.871,35.18,523.069,38.245,523.069,41.77z M486.069,18.708
			c0.1,0.459,0.198,0.996,0.399,1.532c3.09-1.227,3.989-5.21,4.088-7.968c0-1.456-0.099-2.682-0.3-3.678
			C487.266,10.969,485.669,14.8,486.069,18.708z M490.557,20.163c-1.694,0.689-3.092,0.689-3.99,0.536
			c0.599,1.762,1.696,3.601,3.99,4.674V20.163z M496.94,4.763V5.07c2.491,0.459,4.786,1.762,6.582,3.831
			c1.695,1.608,2.991,3.907,1.992,6.895c0,0-0.797,2.146-3.69,1.303c4.59-5.21,0.101-9.194,0.101-9.194
			c-1.495-0.919-3.191-1.456-4.984-1.532v20.227h5.084c3.49,0,6.284-0.919,8.381-2.912c2.092-1.916,3.09-4.597,3.09-7.892
			c0-3.448-0.998-6.206-3.09-8.122c-1.997-1.915-4.891-2.911-8.581-2.911H496.94z M515.59,41.31c0-3.831-1.296-6.895-3.791-9.271
			c-2.593-2.299-5.983-3.448-10.271-3.448h-4.588v0.078h0.099h-0.099v15.092c0,3.295,0.398,5.594,1.097,6.82
			c0.696,1.226,1.993,1.838,3.987,1.838h3.99c2.794,0,5.087-0.996,6.882-3.064C514.692,47.362,515.59,44.681,515.59,41.31z"/>
		<path d="M571.862,2.904c-3.167,2.059-4.156,2.746-4.156,2.746c-0.99,0.686-2.176,2.211-3.562,4.576L553.26,28.455
			v16.17c0,3.127,0.396,5.263,1.188,6.407c0,0,0.692,1.144,4.156,3.28H540.99c3.957-2.136,4.749-3.356,4.749-3.356
			c0.792-1.22,1.188-3.356,1.188-6.331v-16.17l-5.54-9.153c0,0-3.464,2.441-6.433,1.22c2.474-0.534,3.662-2.593,4.255-4.805
			l-2.573-4.348c-1.682-2.822-3.065-4.652-4.156-5.492c0,0-1.088-0.915-4.452-2.974h18.899c-5.045,2.288-5.045,3.89-5.045,3.89
			c0,0.763,0.395,1.678,0.989,2.746l9.597,15.56l7.619-12.89c1.188-1.907,1.683-3.585,1.683-4.958c0,0,0-1.907-3.166-4.348H571.862z"
			/>
		<path d="M150.047,160.982c-6.08-6.504-12.159-9.758-23.524-13.213c-1.057,2.846-1.586,6.505-1.586,10.978v36.797
			c-3.172,1.017-8.722,1.829-16.916,2.439C99.827,198.593,93.483,199,88.99,199c-24.053,0-42.555-6.1-55.77-18.704
			c-12.688-11.791-19.295-27.444-19.824-46.555c0,0-7.4,1.627-12.422-1.423c6.079-0.406,10.044-4.473,12.687-9.555
			c0.793-10.979,3.7-20.939,8.458-30.088c5.815-11.588,13.744-20.533,24.316-27.242c10.309-6.708,21.41-10.165,33.304-10.165
			c6.872,0,14.537,1.423,22.73,4.066c5.287,1.626,9.516,2.439,12.159,2.439c0,0,3.7,0,11.365-4.473v40.253
			c-9.779-20.126-17.974-26.835-17.974-26.835c-8.193-6.709-17.444-10.165-28.281-10.165c-13.744,0-24.846,5.896-33.567,17.483
			c-8.723,11.385-13.216,26.43-13.216,44.726c0,19.922,5.021,36.593,15.33,49.808c10.044,13.418,22.467,19.923,37.797,19.923
			c14.537,0,21.674-4.878,21.674-14.638v-14.841c0-8.132-1.058-17.483-2.907-21.143c-0.266,0-0.266,0-0.266,0
			c-0.264,0-0.264,0-0.264,0c-5.551-1.422-11.895-3.252-19.03-5.083c0,0-42.026-11.791-45.197-38.219
			c5.551,11.994,23.259,19.517,64.491,28.258C104.584,126.829,146.081,135.774,150.047,160.982z M103.791,141.873
			c0.265,0,0.265,0,0.529,0C104.056,141.873,104.056,142.077,103.791,141.873z"/>
		<path d="M192.167,195.652h-45.639c9.389-5.685,11.475-8.729,11.475-8.729c1.826-3.046,2.869-8.73,2.869-17.054
			v-64.763c0,0-6.781,2.843-12.258,0.609c8.346-1.826,11.215-10.557,12.258-17.865v-3.248c0-8.324-1.043-14.211-2.869-17.256
			c0,0-2.086-3.045-11.475-8.526h45.639c-9.128,5.481-11.214,8.729-11.214,8.729c-2.086,3.249-3.13,8.933-3.13,17.054v85.267
			c0,8.12,1.044,13.805,3.13,17.054C180.953,186.923,183.039,189.968,192.167,195.652z"/>
		<path d="M347.371,58.82c-10.587,5.481-12.969,8.729-12.969,8.729c-2.381,3.045-3.706,8.73-3.706,16.851v111.253
			L235.425,76.685v96.026c0,6.7,1.323,11.572,3.97,14.415c0,0,2.646,3.045,12.968,8.526h-39.167
			c10.586-5.887,12.702-9.34,12.702-9.34c2.117-3.45,3.176-9.541,3.176-18.067v-63.747c0,0-7.675,3.654-13.761,1.218
			c13.761-3.046,12.967-24.158,12.702-28.219c-0.529-3.249-1.853-6.09-3.44-7.918c0,0-2.911-3.857-14.556-10.759H243.1l80.981,106.38
			V85.211c0-8.324-1.059-14.211-3.175-17.459c0,0-2.382-3.249-12.439-8.932H347.371z"/>
		<path d="M497.983,58.82c-10.587,5.481-12.969,8.729-12.969,8.729c-2.381,3.045-3.705,8.73-3.705,16.851v111.253
			L386.037,76.685v96.026c0,6.7,1.322,11.572,3.97,14.415c0,0,2.646,3.045,12.968,8.526h-39.167
			c10.584-5.887,12.702-9.34,12.702-9.34c2.117-3.45,3.176-9.541,3.176-18.067v-63.747c0,0-7.676,3.654-13.761,1.218
			c13.761-3.046,12.967-24.158,12.702-28.219c-0.529-3.249-1.853-6.09-3.44-7.918c0,0-2.912-3.857-14.556-10.759h33.081
			l80.981,106.38V85.211c0-8.324-1.06-14.211-3.175-17.459c0,0-2.381-3.249-12.439-8.932H497.983z"/>
		<path d="M628.025,58.82c-8.43,5.481-11.065,7.309-11.065,7.309c-2.633,1.827-5.795,5.887-9.482,12.181L578.5,126.83
			v43.039c0,8.323,1.052,14.008,3.162,17.054c0,0,1.844,3.045,11.062,8.729h-46.892c10.538-5.685,12.645-8.933,12.645-8.933
			c2.108-3.248,3.163-8.934,3.163-16.851V126.83l-14.753-24.363c0,0-9.221,6.497-17.124,3.249c6.586-1.42,9.748-6.902,11.328-12.79
			l-6.85-11.571c-4.479-7.512-8.165-12.384-11.063-14.618c0,0-2.898-2.436-11.855-7.917h50.317
			c-13.436,6.09-13.436,10.354-13.436,10.354c0,2.03,1.054,4.466,2.634,7.309l25.553,41.416l20.285-34.31
			c3.161-5.075,4.479-9.542,4.479-13.196c0,0,0-5.075-8.431-11.572H628.025z"/>
		<path d="M190.569,49.649c0-0.609-0.195-1.294-0.392-1.979l-3.225-10.277c-2.443,0.153-5.669-0.38-9.773-2.359
			c-1.661,2.665-4.495,5.557-8.894,5.176l-1.075,3.502c-0.879,2.588-1.271,4.492-1.271,5.634c0,0,0,2.207,3.812,4.872h-12.313
			c4.983-3.197,7.036-9.591,7.036-9.591l1.563-4.873c0,0-6.254-1.828-6.156-7.384c0.684,5.252,6.352,6.775,6.352,6.775l5.962-19.032
			c0,0-2.736,1.218-4.984,0.38c3.421-0.761,5.767-4.491,7.135-7.384l1.661-5.481c0,0,1.271-4.187-0.879-5.252h10.066
			c-2.248,1.066-0.978,5.252-0.978,5.252l7.818,24.969c0.978-0.457,1.955-1.065,2.835-1.903c-0.392,2.132-2.151,3.958-2.151,3.958
			l3.714,11.876c0.782,2.588,1.662,4.263,2.542,5.024c0,0,0.879,0.761,4.202,2.665h-16.712
			C190.569,51.704,190.569,49.649,190.569,49.649z M185.683,33.129l-0.879-2.893L178.06,8.541l-6.743,21.696l-0.88,3.197
			l-1.954,6.09h0.097c7.624,0.609,9.48-11.723,9.48-12.561c0,0.305-0.098,3.045,1.074,3.654
			C182.066,32.444,185.683,33.129,185.683,33.129z M178.06,26.887c0,0,0,0,0,0.076V26.887z"/>
		</g>
	</svg>
)

export default TatsByText
