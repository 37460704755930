import React from 'react'

import Layout from '../components/layout'
import Hero from '../components/hero'
import About from '../components/about'
import InstagramSection from '../components/instagram'
import FAQs from '../components/faqs'
import Contact from '../components/contact'

const IndexPage = () => (
  <Layout>
    <Hero />
    <About />
    <InstagramSection />
    <FAQs />
    <Contact />
  </Layout>
)

export default IndexPage
