import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import FaqImage from './FaqImage'

const FaqSection = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  text-shadow: 0px 2px 16px rgba(30, 33, 36, 0.45);
`

const Content = styled.div`
  max-width: 640px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
`

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

const Faq = styled.div`
  &:not(:last-child) {
    margin-bottom: 1.75rem;
  }
`

const Question = styled.p`
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
`

const Answer = styled.p`
  margin-bottom: 0;
  font-size: 0.875rem;

  &:before {
    content: '- ';
  }
`

const Faqs = () => {
  const data = useStaticQuery(graphql`
    query FaqQuery {
      allContentfulFaq {
        edges {
          node {
            id
            question {
              question
            }
            answer {
              answer
            }
          }
        }
      }
    }
  `)

  return (
    <FaqSection>
      <FaqImage />
      <Content>
        <Title>Frequently Asked Questions</Title>
        {data.allContentfulFaq.edges.map(({ node }) => {
          return (
            <Faq key={node.id}>
              <Question>{node.question.question}</Question>
              <Answer>{node.answer.answer}</Answer>
            </Faq>
          )
        })}
      </Content>
    </FaqSection>
  )
}

export default Faqs
