import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import Icon from './icon'
import { mapMarker, rose } from './constants'

const ContactSection = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  text-shadow: 0px 2px 16px rgba(30, 33, 36, 0.45);
`

const Content = styled.div`
  max-width: 480px;
  margin: 0 auto;
  padding: 3rem 1.5rem;
`

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`

const FlexRow = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  flex: 1;
  text-align: center;
  margin-bottom: 3rem;

  @media (min-width: 768px) {
    &:first-child {
      margin-right: 3rem;
      text-align: right;
    }

    &:last-child {
      margin-left: 3rem;
      text-align: left;
    }
  }
`

const ColumnTitle = styled.h3`
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
`

const ColumnContent = styled.div`
  line-height: 1.5rem;
`

const Link = styled.a`
  display: block;
  color: #FAF0E6;
  text-decoration: none;
  padding: 12px 0;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    padding: 8px 0;
  }
`

const MapMarker = styled.a`
  position: absolute;
  top: calc(50% - 36px);
  left: calc(50% - 18px);
  transition: transform .2s ease;

  &:hover {
    transform: scale(1.1) translateY(-0.25rem);
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      file(relativePath: {eq: "Map.jpg"}) {
        childImageSharp {
          fluid(maxHeight: 761, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contentfulContact {
        addressLine1
        addressLine2
        postCode
        googleMapsLink
        openHours
        phone
        telephone
        email
      }
    }
  `)
  const {
    addressLine1,
    addressLine2,
    postCode,
    googleMapsLink,
    openHours,
    phone,
    telephone,
    email
  } = data.contentfulContact

  return (
    <ContactSection>
      <Content>
      <Img
        sizes={data.file.childImageSharp.fluid}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: -1
        }}
      />
      <Title>Contact</Title>
      <FlexRow>
        <Column>
          <ColumnTitle>Find us</ColumnTitle>
          <ColumnContent>
            <Link href={googleMapsLink} target="_blank" rel="noopener noreferrer">
              <address>
                {addressLine1}<br />
                {addressLine2}<br />
                {postCode}<br />
              </address>
            </Link>
            <p>{openHours}</p>
          </ColumnContent>
        </Column>
        <Column>
        <ColumnTitle>Get in touch</ColumnTitle>
          <ColumnContent>
            <Link href={`tel:${telephone}`}>{telephone}</Link>
            <Link href={`tel:${phone}`}>{phone}</Link>
            <Link href={`mailto:${email}?subject=Website enquiry`}>Email us</Link>
          </ColumnContent>
        </Column>
      </FlexRow>
          <MapMarker href={googleMapsLink} target="_blank" rel="noopener noreferrer">
            <Icon
              icon={mapMarker}
              colour="#FAF0E6"
              size="36"
              viewbox="0 0 24 24"
            />
          </MapMarker>
        <Icon
          icon={rose}
          colour="#FAF0E6"
          size="64"
        />
      </Content>
    </ContactSection>
  )
}

export default Contact
