import React from 'react'
import { Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'

import HeroImage from './HeroImage'
import Icon from './icon'
import { daggers, chevronDown } from './constants'
import NoClassLogo from './NoClassLogo'
import TatsByText from './TatsByText'

const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const bounce = keyframes`
  from, to, 20% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(-1rem);
  }
`;

const Button = styled.a`
  background: none;
  border: none;
  padding: 0.5rem;
  box-sizing: border-box;
  animation: ${bounce} 3s infinite;
`

class Hero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowHeight: '100vh'
    }

    this.handleScrollDown = this.handleScrollDown.bind(this)
  }

  componentDidMount() {
    this.setState({ windowHeight: `${window.innerHeight}px` })
  }

  handleScrollDown(e) {
    const amountToScroll = this.heroSectionRef.clientHeight
    e.preventDefault()
    window.scroll({
      top: amountToScroll,
      behavior: 'smooth'
    })
  }

  render() {
    return (
      <HeroSection
        ref={node => this.heroSectionRef = node}
        style={{ height: this.state.windowHeight }}
      >
        <HeroImage />
        <Content>
          <Link
            to="/"
            title="No Class Tattoo Studio"
          >
            <NoClassLogo width={280} colour="#FAF0E6" />
          </Link>
          <Icon
            icon={daggers}
            colour="#FAF0E6"
            size="80"
          />
          <TatsByText width={200} colour="#FAF0E6" />
          <Button
            href="#about"
            onClick={this.handleScrollDown}
            aria-label="Click to scroll down"
          >
            <Icon
              icon={chevronDown}
              colour="#FAF0E6"
              size="48"
              viewbox="0 0 24 24"
            />
          </Button>
        </Content>
      </HeroSection>
    )
  }
}

export default Hero
