import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const ImgContainer = styled.div`
  display: block;
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  z-index: -1;
  filter: grayscale(100%) blur(2px) brightness(40%);
`

const StyledImg = styled(Img)`
  min-width: 100%;
  min-height: 100%;
`

const FaqImage = () => {
  const data = useStaticQuery(graphql`
    query FaqImageQuery {
      file(relativePath: {eq: "ginny-2.jpeg"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <ImgContainer>
      <StyledImg sizes={data.file.childImageSharp.fluid} />
    </ImgContainer>
  )
}

export default FaqImage
